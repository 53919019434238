import { takeLatest, put, delay } from 'redux-saga/effects';
import {
  cookieAuth,
  urlAuth,
  emailAuth,
  anyAuth
} from './../redux/actions';
import { authViaCookieCall } from './aqp-api-helper.js';
import { singleAsync } from './saga-helper.js';
import { aquaplotStore } from './../index';

function* handleCookieAuth(action) {
  console.debug('handleCookieAuth')
  yield singleAsync(authViaCookieCall, cookieAuth.SUCCEEDED, cookieAuth.FAILED, action.payload, 0, (response) => {
      window.onblur = () => {
          window.onfocus = () => {
            window.onblur = () => {};
            const state = aquaplotStore.getState();
            if(state && state.user && state.user.loggedIn === false){
                console.debug('regaining focus. auth via cookie again')
                aquaplotStore.dispatch({
                    type: cookieAuth.REQUESTED,
                    payload: {}
                })
            }
            else if(state && state.user && state.user.loggedIn === true){
                console.debug('regaining focus. auth not necessary as still logged in');
            }
            else{
                console.debug('regaining focus. but something is wrong. no-op');
            }
          }
        
      } 
      return response;
  });
}

export function* cookieAuthSaga() {
  yield takeLatest(cookieAuth.REQUESTED, handleCookieAuth);
}

function* handleCookieAuthFailed({ payload }) {
    // console.log(payload)
    if(!payload || 
        !payload.response || 
        !payload.response.reason){
        if (payload && payload.response && payload.response.reason && payload.response.reason.message){
            // not an error message originated from aqp
            // let it be - not requesting cookieAuth again and again 
            // prevents app from flashing each time as it gets rerendered 
        }
        else if(payload && payload.response && payload.response.reason !== "no session"){
            // wait for a couple of seconds, then try again
            yield delay(10e3);
            yield put({
                type: cookieAuth.REQUESTED,
                payload: {}
            })
        }
    }
}

export function* cookieAuthFailedSaga() {
    yield takeLatest(cookieAuth.FAILED, handleCookieAuthFailed);
}

function* handleUrlAuth(action){
    yield put({
        type: urlAuth.SUCCEEDED,
        payload: { aqp_token: action.payload }
    });
}

export function* urlAuthSaga() {
    yield takeLatest(urlAuth.REQUESTED, handleUrlAuth);
  }

const triggersRequested = [
    cookieAuth.REQUESTED,
    emailAuth.REQUESTED,
    urlAuth.REQUESTED
];

function* authRequested(action){
    yield put({
        type: anyAuth.REQUESTED,
        payload: action.type
    })
}

const triggersSucceeded = [
    cookieAuth.SUCCEEDED,
    emailAuth.SUCCEEDED,
    urlAuth.SUCCEEDED
];

function* authSucceeded(action){
    yield put({
        type: anyAuth.SUCCEEDED,
        payload: action.payload
    })
}

const triggersFailed = [
    cookieAuth.FAILED,
    emailAuth.FAILED,
    urlAuth.FAILED
];

function* authFailed(action){
    // console.log(action)
    yield put({
        type: anyAuth.FAILED,
        payload: {
            showError: !action.payload.response || !action.payload.response.reason || action.payload.response.reason !== 'no session'
        }
    });
    yield delay(2e3);
    yield put({
        type: anyAuth.FAILED,
        payload: {
            showError: false
        }
    });
}

export function* authSaga(){
    yield takeLatest(triggersRequested, authRequested);
    yield takeLatest(triggersSucceeded, authSucceeded);
    yield takeLatest(triggersFailed, authFailed);
}