import {
    compose,
    branch,
    withState,
    withHandlers,
    renderNothing,
    lifecycle
} from 'recompose';
import withLeaflet from './../../../../common/Leaflet/withLeaflet';
import { connect } from 'react-redux';
import { antPath } from 'leaflet-ant-path';
import { Colors } from './../../../../aquaplot/colors';
import { denormalizedRoutes } from './../helper';

import L, { DomEvent } from 'leaflet';
// import buffer from '@turf/buffer';

import './style.css'

const connectorOptions = {
    color: 'transparent',
    dashArray: [10, 20],
    weight: 2,
    pulseColor: Colors.aqp_deep_sea,
  }

const routePathStyle = () => {
    return {
        color: Colors.aqp_deep_sea, 
        dashArray: "5 5", 
        weight: 2,
    }
}

const hoverRoutePathStyle = () => {
    return {
        color: Colors.aqp_deep_sea, 
        dashArray: null,
        weight: 8
    }
}

const errorPathStyle = () => {
    return {
        color: Colors.aqp_error, 
        dashArray: "5 5", 
        weight: 2,
    }
}

const windyRoutePathStyle = () => {
    return {
        color: Colors.aqp_white, 
        dashArray: "5 5", 
        weight: 2
    }
}

const inactiveRoutePathStyle = () => {
    return {
        color: Colors.aqp_gray, 
        dashArray: "5 5", 
        weight: 2
    }
}

const windyHoverRoutePathStyle = () => {
    return {
        color: Colors.aqp_white, 
        dashArray: null,
        weight: 8
    }
}

const mapStateToProps = state => {
    return {
        segments: state.routing.segments,
        inactiveSegments: state.routing.inactiveSegments,
        autocalculate: state.routing.autocalculate
    }
}

const moveMarkerAlong = (m) => (e) => {
    m.setLatLng(e.latlng);
}

const Handlers = compose(
    withLeaflet,
    connect(mapStateToProps),
    withHandlers({
        defaultAntpathObject: () => () => {
            return { 
                paths: null, 
                map: null, 
                coords: [] 
            };
        },
        defaultRoutesObject: () => () => {
            return { 
                geojsonData: [],
                inactivesGeojsonData: [],
                inactivesGeojson: null,
                geojson: null,
                map: null, 
            };
        }
    }),
    withState('antpaths', 'setAntpaths', ({ defaultAntpathObject }) => defaultAntpathObject()),
    withState('routes', 'setRoutes', ({ defaultRoutesObject }) => defaultRoutesObject()),
    withState('errors', 'setErrors', ({ defaultRoutesObject }) => defaultRoutesObject()),
    withHandlers({
        updateAntpaths: ({
            segments,
            antpaths: {
                paths,
                map,
                coords
            },
            setAntpaths,
            leafletMap,
            isWindyMap,
            defaultAntpathObject,
        }) => () => {

            const processing_segments = segments.filter(seg => seg.processing);
            const antpathCoords = processing_segments.map(seg => [seg.from, seg.to]);

            //need to 'rerender' if either map has changed or paths have changed
            const mapsChanged = leafletMap !== map;
            const coordsChanged = JSON.stringify(antpathCoords) !== JSON.stringify(coords);
            // console.log(mapsChanged, coordsChanged, antpathCoords, coords)

            if(leafletMap && (mapsChanged || coordsChanged)){
                if(paths && map){
                    //clear existing
                    paths.forEach(path => map.removeLayer(path));
                }

                const antpaths = antpathCoords.map(coords => antPath(coords, connectorOptions));
                antpaths.forEach(path => path.addTo(leafletMap));
                setAntpaths({ paths: antpaths, map: leafletMap, coords: antpathCoords });
                
            }
            else if(!leafletMap && (map || coords.length > 0 || paths)){
                setAntpaths(defaultAntpathObject());
            }
        },
        updateCalculatedRoutes: ({
            segments,
            inactiveSegments,
            routes: {
                geojsonData,
                inactivesGeojsonData,
                map,
                geojson,
                inactivesGeojson
            },
            setRoutes,
            leafletMap,
            isWindyMap,
            defaultRoutesObject,
        }) => () => {
            const segmentIsValid = (seg) => seg.route && seg.route.status && seg.route.status === "ok" && !seg.error && !seg.processing;
            const valid_segments = segments.filter(segmentIsValid);
            const valid_inactive_segments = inactiveSegments.reduce((acc, curr) => acc.concat(curr.segments),[]).filter(segmentIsValid);
            console.log('valid_inactive_segments', valid_inactive_segments);
            console.log(valid_segments)
            // const buffered = valid_segments.map(({ route }) => buffer(route, 500, {units: 'nauticalmiles'}));
            // console.log(JSON.stringify(buffered))
            const routesData = denormalizedRoutes(valid_segments.map((seg) => seg.route));
            const inactiveRoutesData = denormalizedRoutes(valid_inactive_segments.map((seg) => seg.route));

            //need to 'rerender' if either map has changed or routesData has changed
            const mapsChanged = leafletMap !== map;
            const routesDataChanged = JSON.stringify(routesData) !== JSON.stringify(geojsonData);
            const inactiveRoutesDataChanged = JSON.stringify(inactiveRoutesData) !== JSON.stringify(inactivesGeojsonData);
            // console.log('inactiveRoutesData', inactiveRoutesData, 'inactiveRoutesDataChanged', inactiveRoutesDataChanged);

            if(leafletMap && (mapsChanged || routesDataChanged || inactiveRoutesDataChanged)){
                if(geojson && map){
                    //clear existing
                    map.removeLayer(geojson);
                }
                if(inactivesGeojson && map){
                    //clear existing
                    map.removeLayer(inactivesGeojson);
                }

                if(isWindyMap){
                    // console.log('adding')
                    const fg = L.geoJson(routesData, {
                        style: windyRoutePathStyle
                    });

                    const inactivesFg = L.geoJson(inactiveRoutesData, {
                        style: inactiveRoutePathStyle
                    });
                    

                    const mousedownHandler = (layer) => (e) => {
                        // console.log('mousedown', e.latlng);
                        DomEvent.stopPropagation(e);
                        var myIcon = L.divIcon({className: 'my-div-icon'});
                        const m = L.marker(e.latlng, {radius: 8e3, icon: myIcon});
                        leafletMap.on('mousemove', moveMarkerAlong(m));
                        leafletMap.once('mouseup', () => {
                            // console.log('mouseup');
                            leafletMap.off('mousemove');
                            leafletMap.removeLayer(m);
                            leafletMap.fire('aqpWaypointAdd', {
                                latlng: m.getLatLng(),
                                segment_id: layer.feature.properties.id,
                            });
                        })
                        m.addTo(leafletMap);
                    };

                    fg.on('mouseover', (e) => {
                        // console.log('mouseover');
                        e.layer.setStyle(windyHoverRoutePathStyle());
                        leafletMap.dragging.disable();
                        leafletMap.on('mousedown', mousedownHandler(e.layer));
                    })
                    fg.on('mouseout', (e) => {
                        // console.log('mouseout');
                        e.layer.setStyle(windyRoutePathStyle());
                        leafletMap.dragging.enable();
                        leafletMap.off('mousedown');
                    })

                    inactivesFg.addTo(leafletMap);
                    fg.addTo(leafletMap);

                    setRoutes({ 
                        map: leafletMap, 
                        geojson: fg, 
                        geojsonData: routesData, 
                        inactivesGeojson: inactivesFg,
                        inactivesGeojsonData: inactiveRoutesData
                    });
                }
                else{
                    // console.log('adding')
                    const fg = L.geoJSON(routesData, {
                        style: routePathStyle
                    });

                    const inactivesFg = L.geoJson(inactiveRoutesData, {
                        style: inactiveRoutePathStyle
                    });

                    const mousedownHandler = (e) => {
                        // console.log('mousedown', e.latlng)
                        DomEvent.stopPropagation(e);
                        var myIcon = L.divIcon({className: 'my-div-icon'});
                        const m = L.marker(e.latlng, {radius: 8e3, autoPan: true, icon: myIcon});
                        leafletMap.on('mousemove', moveMarkerAlong(m))
                        leafletMap.once('mouseup', () => {
                            leafletMap.off('mousemove');
                            leafletMap.removeLayer(m);
                            leafletMap.fire('click', {
                                latlng: m.getLatLng(),
                                segment_id: e.layer.feature.properties.id
                            })
                        })
                        m.addTo(leafletMap);
                    };

                    fg.on('mouseover', (e) => {
                        e.layer.setStyle(hoverRoutePathStyle());
                        leafletMap.dragging.disable();
                        fg.on('mousedown', mousedownHandler);
                    })
                    fg.on('mouseout', (e) => {
                        e.layer.setStyle(routePathStyle());
                        leafletMap.dragging.enable();
                        fg.off('mousedown', mousedownHandler);
                    })
                    
                    inactivesFg.addTo(leafletMap);
                    fg.addTo(leafletMap);

                    setRoutes({ 
                        map: leafletMap, 
                        geojson: fg, 
                        geojsonData: routesData,
                        inactivesGeojson: inactivesFg,
                        inactivesGeojsonData: inactiveRoutesData 
                    });
                }
                
            }
            else if(!leafletMap && (map || geojsonData.length > 0 || geojson || inactivesGeojsonData.length > 0 || inactivesGeojson)){
                setRoutes(defaultRoutesObject());
            }
        },
        updateFailedRoutes: ({
            segments,
            errors: {
                geojsonData,
                map,
                geojson
            },
            setErrors,
            leafletMap,
            isWindyMap,
            defaultRoutesObject,
        }) => () => {
            const failed_segments = segments.filter(seg => seg.error && !seg.processing);
            const latlngs = failed_segments.map(seg => [seg.from, seg.to]);

            //need to 'rerender' if either map has changed or routesData has changed
            const mapsChanged = leafletMap !== map;
            const routesDataChanged = JSON.stringify(latlngs) !== JSON.stringify(geojsonData);

            if(leafletMap && (mapsChanged || routesDataChanged)){
                if(geojson && map){
                    //clear existing
                    map.removeLayer(geojson);
                }

                if(isWindyMap){
                    // console.log('adding')
                    const fg = L.polyline(latlngs, errorPathStyle());

                    fg.addTo(leafletMap);
                    setErrors({ map: leafletMap, geojson: fg, geojsonData: latlngs });
                }
                else{
                    // console.log('adding')
                    const fg = L.polyline(latlngs, errorPathStyle());
                    
                    fg.addTo(leafletMap);
                    setErrors({ map: leafletMap, geojson: fg, geojsonData: latlngs });
                }
                
            }
            else if(!leafletMap && (map || geojsonData.length > 0 || geojson)){
                setErrors(defaultRoutesObject());
            }
        }
    }),
    lifecycle({
        componentDidMount(){
            this.props.updateAntpaths();
            this.props.updateCalculatedRoutes();
            this.props.updateFailedRoutes();
            // console.log('mounted');
        },
        componentDidUpdate(){
            this.props.updateAntpaths();
            this.props.updateCalculatedRoutes();
            this.props.updateFailedRoutes();
            // console.log('updated');
        },
        componentWillUnmount(){
            this.props.updateAntpaths();
            this.props.updateCalculatedRoutes();
            this.props.updateFailedRoutes();
            // console.log('unmount')
        },
    }),
    branch(() => true, renderNothing)
)

export default Handlers;