import * as actions from './../actions';
import { update } from './helper';
import UUID from 'uuid-js';

const initial_layers = [
  {
    uuid: UUID.create().toString(),
    name: 'ArcGIS Ocean Basemap',
    url:
      'https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}',
    attr:
      'Sources: Esri, GEBCO, NOAA, National Geographic, DeLorme, HERE, Geonames.org, and other contributors ',
    maxZoom: 10
  },
  {
    uuid: UUID.create().toString(),
    name: 'OpenSeaMap',
    url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
    attr:
      'Map data &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    maxZoom: 16
  },
];

const modes = {
  automatic: 'automatic',
  deactivated: 'deactivated',
  customArea: 'customArea',
  deleteArea: 'deleteArea'
};

const layers_info_modes = {
  on: 'active',
  off: 'inactive',
};

const initialMapState = {
  leafletObj: {
    leafletMap: null,
    isWindyMap: false,
    hideData: false
  },
  activeBaseLayer: initial_layers[0],
  center: { lat: 0, lng: 0 },
  zoom: 2,
  layers: initial_layers,
  showZoomControl: true,
  showEcaAreas: false,
  showHighRiskAreas: false,
  showJwcAreas: false,
  showCustomAreaControl: true,
  areasSelectable: false,
  mode: modes.automatic,
  modes: modes,
  requireDoubleConfirm: false,
  modeHistory: [modes.automatic],
  layers_info_modes: layers_info_modes, //currently unused, idea is to allow different areas to be clicked on i.e. channel area etc
  layers_info_mode: layers_info_modes.off,
  requireWeatherMap: false // if set to true we'll show windy map
};

function mapStateReducer(mapState = initialMapState, action) {
  switch (action.type) {
    case actions.setCurrentViewport.type:
      return update(mapState, action.payload);

    case actions.setLeafletObject.type:
      return update(mapState, {
        leafletObj: action.payload
      });

    case actions.toggleRequireDoubleConfirmation.type:
      return update(mapState, {
        requireDoubleConfirm: !mapState.requireDoubleConfirm
      });

    case actions.toggleRequireWeatherMap.type:
      return update(mapState, {
        requireWeatherMap: !mapState.requireWeatherMap
      });

    case actions.setLocationAddMode.type:
      return update(mapState, {
        mode: action.payload,
        modeHistory: [action.payload].concat(mapState.modeHistory)
      });

    case actions.activateCustomAreaAddMode.type:
      return update(mapState, {
        mode: modes.customArea,
        areasSelectable: false
      })

    case actions.activateCustomAreaDeleteMode.type:
      return update(mapState, {
        mode: modes.deleteArea,
        areasSelectable: true
      })

    case actions.exitCustomAreaMode.type:
      const returnTo = mapState.modeHistory.find(mode => mode !== modes.customArea && modes.deleteArea);
      return update(mapState, {
        mode: returnTo,
        modeHistory: [returnTo].concat(mapState.modeHistory),
        areasSelectable: false
      })

    case actions.setActiveBaselayer.type:
      return update(mapState, {
        activeBaseLayer: action.payload,
      });

    case actions.toggleMapSettingsZoomControl.type:
      return update(mapState, {
        showZoomControl: !mapState.showZoomControl,
      });

    case actions.toggleMapSettingsDrawCustomArea.type:
      return update(mapState, {
        showCustomAreaControl: !mapState.showCustomAreaControl,
      });

    case actions.toggleMapSettingsScale.type:
      if (action.payload.forced) {
        return update(mapState, {
          showScaleControl: action.payload.value,
        });
      }
      return update(mapState, {
        showScaleControl: !mapState.showScaleControl,
      });

    case actions.toggleMapSettingsEcaAreas.type:
      return update(mapState, {
        showEcaAreas: !mapState.showEcaAreas,
      });

    case actions.toggleMapSettingsHighRiskAreas.type:
      return update(mapState, {
        showHighRiskAreas: !mapState.showHighRiskAreas,
      });

    case actions.toggleMapSettingsJwcAreas.type:
      return update(mapState, {
        showJwcAreas: !mapState.showJwcAreas,
      });

    default:
      return mapState;
  }
}

export default mapStateReducer;
