import { takeLatest } from 'redux-saga/effects';
import {
  anyAuth,
  ECA_ALL_SUCCEEDED as SUCCESS,
  ECA_ALL_FAILED as FAILURE,
} from './../redux/actions';
import { singleAsync } from './saga-helper.js';
import { getEcaAllCall } from './aqp-api-helper.js';

const PolygonGeometry = {
  type: 'Polygon',
  coordinates: [],
};

const GeojsonFeature = {
  type: 'Feature',
  properties: {},
  geometry: PolygonGeometry,
};

const GeojsonFeatureCollection = {
  type: 'FeatureCollection',
  features: [],
};

const success_transform = response => {
  let coll = Object.assign({}, GeojsonFeatureCollection);
  const ecas = response.eca_areas;

  for (let key of Object.keys(ecas)) {
    const geometry = Object.assign({}, PolygonGeometry, {
      coordinates: [ecas[key]],
    });
    const feature = Object.assign({}, GeojsonFeature, { geometry: geometry });
    coll.features.push(Object.assign({}, feature));
  }

  //console.log(JSON.stringify(coll));

  return coll;
};

function* getAllEcaAreas(action) {
  // const ecaAreas = yield select(state => state.routing.ecaAreas);
  // if(ecaAreas && ecaAreas.type) return;

  yield singleAsync(
    getEcaAllCall,
    SUCCESS,
    FAILURE,
    action.payload,
    0,
    success_transform
  );
}

export default function* getAllEcaAreasSaga() {
  yield takeLatest(anyAuth.SUCCEEDED, getAllEcaAreas);
}
