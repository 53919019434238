import React from 'react';
import { withRouter } from 'react-router-dom';

import ClearMapButton from './ClearMapButton';
import MapSettingsButton from './MapSettingsButton';
import MapFullScreenButton from './MapFullScreen';
import MapZoomZontrol from './MapZoomZontrol';
import CustomAreaButton from './CustomAreaButton';
import MapLayerSelect from './MapLayerSelect';

import { ControlContainer, FlexGrowDiv } from './styledComponents';


class MapControls extends React.Component {
  render() {
    const { disabled } = this.props;

    if (disabled) return null;

    return (
      <ControlContainer>
        <MapFullScreenButton />
        <ClearMapButton />
        <MapLayerSelect />
        <MapSettingsButton />
        <CustomAreaButton />

        <FlexGrowDiv />

        <MapZoomZontrol />
      </ControlContainer>
    );
  }
}

const MapControlsContainer = withRouter(MapControls);

export default MapControlsContainer;
