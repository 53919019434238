import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from './../../illustrations/3546-loading-a.json';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { WrapperDiv, Message } from './styledComponents';

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const LoadingScreen = ({ message }) => {
  return (
    <WrapperDiv>
      <Lottie 
          options={defaultOptions}
          height={80}
          width={200}
      />
      {/* <CircularProgress size={50} thickness={2.6} /> */}
      <Message>{ message || 'Loading'}</Message>
    </WrapperDiv>
  );
};
export default LoadingScreen;
