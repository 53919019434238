import { debounce } from 'redux-saga/effects'

import { singleAsync } from './saga-helper.js'
import { ninetyPercentRouteApiCall } from '../../services/ninetyPercent';
import {
  NinetyPercentRouteCall,
  SecondNinetyPercentRouteCall
} from './../redux/actions';


function* callRouteApi(action) {
    const { payload, successCallback } = action.payload;
    // console.log(payload, successCallback)
    if(!payload || !payload.routeObject || !payload.routeObject.source) return;
  
    yield singleAsync(ninetyPercentRouteApiCall, NinetyPercentRouteCall.SUCCEEDED, NinetyPercentRouteCall.FAILED, payload, 0, successCallback, response => ({ response }), 20e3);
  }

  const triggers = [
    NinetyPercentRouteCall.REQUESTED
  ];

  function* callFollowUpRouteApi(action) {
    const { payload, successCallback } = action.payload;
    console.log(payload, successCallback)
    if(!payload || !payload.routeObject || !payload.routeObject.source) return;
  
    yield singleAsync(ninetyPercentRouteApiCall, SecondNinetyPercentRouteCall.SUCCEEDED, SecondNinetyPercentRouteCall.FAILED, payload, 0, successCallback, response => ({ response }), 20e3);
  }
  
  export function* routeApiSagaTwo() {
    yield debounce(2000, SecondNinetyPercentRouteCall.REQUESTED, callFollowUpRouteApi);
  }

  export default function* routeApiSaga() {
    yield debounce(1000, triggers, callRouteApi);
  }

