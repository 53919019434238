import { compose, withHandlers, withProps } from 'recompose';
import QueryString from 'query-string';
import { withRouter } from 'react-router-dom';

const Handlers = compose(
  withRouter,
  withHandlers({
    refreshLink: ({
      urlTrigger, 
      urlParams
    }) => location => {

      const queryStr = [
        `${urlTrigger}=true`,
        urlParams ? '&' : '',
        QueryString.stringify(urlParams || {}),
      ].join('');

      return `${location.pathname}?${queryStr}`;
    },
  }),
  withProps(({
    location,
    refreshLink
  }) => ({
    link: refreshLink(location)
  }))
);

export default Handlers;
