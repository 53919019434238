import React from 'react';
import Handlers from './handlers';

import Vessels from './Vessels';
import Waypoints from './Waypoints';
import EcaAreas from './EcaAreas';
import HighRiskAreas from './HraAreas';
import JointWarCommitteeAreas from './JwcAreas';
import Routes from './Routes';
import CustomArea from './CustomArea';
import NogoAreas from './NogoAreas';
import Deviation from './Deviation';
import RouteMidpoints from './RouteMidpoints';

const MapData = () => (
    <React.Fragment>
        <Vessels /> 
        <Waypoints /> 
        <EcaAreas />
        <HighRiskAreas />
        <JointWarCommitteeAreas />
        <Routes />
        <CustomArea />
        <NogoAreas />
        <Deviation />
        <RouteMidpoints />
    </React.Fragment>
);

export default Handlers(MapData);