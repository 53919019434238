import styled from 'styled-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';


export const StyledExpansionPanel = styled(Accordion)`
  border: 0;
  box-shadow: none !important;
  background-color: transparent !important;
  &:before {
    background-color: transparent !important;
  }
  > div {
    padding: 0px;
  }
`;

export const StyledPanelDetails = styled(AccordionDetails)`
  padding: 0px !important;
  font-size: small;
`;