//@flow

import { call, put, all, select, race, delay } from 'redux-saga/effects'

function* getPassThroughData(){
    const data = yield select(state => 
        Object.assign({}, {
            aqpToken: state.user.aqpToken,
            apiEndpoint: state.dev.apiEndpoint, 
            accountEndpoint: state.dev.accountEndpoint 
        })
    );
    return data;
}

export function* singleAsync(fn, action_success, action_failure, payload = {}, delayMs = 0, success_trans = (result) => result, failure_transform = (response) => { return { response: response }}, hardTimeout = 5000 ) {
    const passThrough = yield getPassThroughData();
    payload = Object.assign(payload, passThrough);
    if(delayMs > 0) yield delay(delayMs)
    try {
        const { response, timeout } = yield race({
            response: call(fn, payload),
            timeout: delay(hardTimeout)
        });
        if(response){
            if(response.status && (response.status !== 200 && response.status !== "ok")){
                //error handling
                yield put({type: action_failure, payload: Object.assign(failure_transform(response), { onFailure: payload.onFailure}) } );
            }
            else{
                yield put({type: action_success, payload: success_trans(response) } ); 
            }
        }
        else if(timeout){
            console.debug("request timed out");
            yield put({type: action_failure, payload: Object.assign({ message: 'request timed out' }, { onFailure: payload.onFailure}) });
        }
        
        
    } catch (e) {
        yield put({type: action_failure, payload: Object.assign({ message: e.message }, { onFailure: payload.onFailure}) });
    }
}

export function* multipleAsync(fn, action_success, action_failure, payload, delayMs = 0, success_trans = (result) => result) {
    const passThrough = yield getPassThroughData();
   if(delayMs > 0) yield delay(delayMs)
   try {
      const response = yield all(payload.map(elem => {
          elem = Object.assign(elem, passThrough);
          return call(fn, elem);
        }));
      
      yield put({type: action_success, payload: success_trans(response) } ); 
   } catch (e) {
      yield put({type: action_failure, payload: Object.assign({ message: e.message }, { onFailure: payload.onFailure}) });
   }
}
