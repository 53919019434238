import React from 'react';
import RouteMidpointMarker from '../../markers/RouteMidpoint';

const Pure = ({
    inactiveSegments
}) => {
    // console.log(data)
    return (
        inactiveSegments.map(({ uuid, midpoint, midpointComponent }) => 
            <RouteMidpointMarker 
                key={uuid}
                latlng={{
                    lat: midpoint[1],
                    lng: midpoint[0]
                }}
                component={midpointComponent}
                uuid={uuid}
            />
        )
    )
}

export default Pure;