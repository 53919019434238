//@flow

import React from 'react';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import LeafletProvider from './../common/Leaflet/Provider';
import ClipboardProvider from './../common/Clipboard/Provider';
import ApiEndpointProvider from './Provider';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import reducer from './redux/reducers';
import sagas from './sagas';
import thunk from 'redux-thunk';
import * as actions from './redux/actions';
import registerOnlineOfflineListener from './connectivity';

export const aquaplotStore = (function getStore() {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(sagaMiddleware, thunk)
    // other store enhancers if any
  );

  const store = createStore(reducer, enhancer);
  sagas.map(saga => sagaMiddleware.run(saga));
  registerOnlineOfflineListener(store);

  //register listener for desktop/mobile view
  const mediaQuery = window.matchMedia('(min-width: 650px)');
  const viewPortFunc = mq => {
    if (mq.matches) {
      store.dispatch(actions.switchToDesktopView.call());
    } else {
      store.dispatch(actions.switchToMobileView.call());
    }
  };

  mediaQuery.addListener(viewPortFunc);
  viewPortFunc(mediaQuery);

  window.onkeyup = ({
    key,
    ctrlKey,
    altKey
  }) => {
    if(!ctrlKey) return;
    switch (key) {
      case 'Backspace':
        store.dispatch(actions.clearWaypoints.call());
        break;
      case 'Enter':
        store.dispatch(actions.Routes.call());
        break;
      case 'd':
      case '∂': //mac
        altKey && console.log(store.getState());
        break;
      case '¬':
        store.dispatch(actions.emailAuth.call("foo@bar.com", "aquaplot", true));
        break;
      default:
        break;
    }
  };

  return store;
})();

const persistor = persistStore(aquaplotStore);

const AqpProvider = ({ children }) => {
  return (
    <Provider store={aquaplotStore}>
      <PersistGate persistor={persistor}>
        <LeafletProvider>
          <ApiEndpointProvider>
            <ClipboardProvider>
              <Router>{children}</Router>
            </ClipboardProvider>
          </ApiEndpointProvider>
        </LeafletProvider>
      </PersistGate>
    </Provider>
  );
}

export default AqpProvider;