import { SagaReduxAction, ReduxAction } from './helper';
import UUID from 'uuid-js';

export const Validation = new SagaReduxAction('VALIDATION', waypoint =>
  Object.assign({}, waypoint)
);

export const RouteChannels = new SagaReduxAction('ROUTE_CHANNELS');

export const setAutocalculate = new ReduxAction("ROUTING_AUTOCALCULATE", flag => flag);
export const setAutovalidate = new ReduxAction("ROUTING_AUTOVALIDATE", flag => flag);

export const addWaypoint = new ReduxAction('WAYPOINT_ADD', (waypoint, id) =>
  Object.assign(
    { 
      uuid: UUID.create().toString(), 
      segment_id: id 
    },
    waypoint
  )
);
export const removeWaypoint = new ReduxAction('WAYPOINT_REMOVE', uuid =>
  Object.assign({ uuid })
);
export const updateWaypoint = new ReduxAction('WAYPOINT_UPDATE', wp => wp);
export const updateWaypointProperties = new ReduxAction('WAYPOINT_UPDATE_PROPERTIES', wp => wp);

export const sortWaypoints = new ReduxAction(
  'WAYPOINT_ALL_SORT',
  (oldIndex, newIndex) =>
    Object.assign({}, { oldIndex: oldIndex, newIndex: newIndex })
);
export const clearWaypoints = new ReduxAction('WAYPOINT_ALL_CLEAR');

export const addPointToCustomArea = new ReduxAction('CUSTOM_AREA_POINT_ADD', point => point);
export const removePointFromCustomArea = new ReduxAction('CUSTOM_AREA_POINT_REMOVE', point => point);
export const addNogoArea = new ReduxAction(
  'NOGO_AREAS_ADD', 
  latlngs => Object.assign({
    latlngs,
    uuid: UUID.create().toString(),
  })
);
export const deleteNogoArea = new ReduxAction(
  'NOGO_AREAS_DELETE',
  uuid => uuid
);
export const clearCustomArea = new ReduxAction('CUSTOM_AREA_POINTS_CLEAR');
export const setEca = new ReduxAction('ECA_SET', eca => Object.assign({ eca }));
export const setHra = new ReduxAction('HRA_SET', hra => Object.assign({ hra }));
export const setJwc = new ReduxAction('JWC_SET', jwc => Object.assign({ jwc }));

export const setDefaultSpeed = new ReduxAction('DEFAULT_SPEED_SET', speed => ({ defaultSpeed: speed }));

export const Routes = new SagaReduxAction('ROUTES', segments => segments);

export const requestSingleRoute = new SagaReduxAction(
  'SINGLE_ROUTE',
  segment => segment
);

export const overwriteRouteData = new ReduxAction(
  'ROUTE_DATA_OVERWRITE', 
  ({ waypoints, segments }) => Object.assign({}, { waypoints, segments }) 
);

export const SETTINGS_CHANNEL_SET = 'SETTINGS_CHANNEL_SET';
export const SETTINGS_CHANNEL_CLOSE = 'SETTINGS_CHANNEL_CLOSE';

export function setOpenChannels(channels) {
  return { type: SETTINGS_CHANNEL_SET, payload: channels };
}

export function closeChannel(channel) {
  return { type: SETTINGS_CHANNEL_CLOSE, payload: channel };
}

export const BufferAroundRoute = new SagaReduxAction(
  'ROUTE_BUFFER',
  (acrossRange, alongRange) => ({
    acrossRange,
    alongRange
  })
);

export const clearBuffer = new ReduxAction('ROUTE_BUFFER_CLEAR');

export const updateRouteOptionsObject = new ReduxAction('ROUTE_OPTIONS_OBJECT_UPDATE', options => options);

export const addToInactiveSegments = new ReduxAction('INACTIVE_ROUTES_ADD', uuid => uuid);
export const updateInactiveSegments = new ReduxAction('INACTIVE_ROUTES_UPDATE', (uuid, data) => ({ uuid, data }));
export const activateCurrentlyInactiveSegments = new ReduxAction('INACTIVE_ROUTES_SET_ACTIVE', uuid => uuid);