import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Colors } from '../../../aquaplot/colors';

export const StyledInput = styled(Input)`
    &&{
        font-size: 16px;
        padding-top: 5px;
        color: ${({ darkmode }) => darkmode === 'true' ? Colors.aqp_white : Colors.aqp_deep_sea};
        opacity: ${({isloading}) => isloading === 'true' ? '0' : '1'}
    }
`

export const StyledLabel = styled(InputLabel)`
    &&,
    &&.Mui-focused{
        white-space: ${({wraplabel}) => wraplabel === 'true' ? 'pre-wrap' : 'nowrap'};
        top: ${({wraplabel}) => wraplabel === 'true' ? '-12px' : '0px'};
        color: ${({ darkmode }) => darkmode === 'true' ? Colors.aqp_lemon : Colors.aqp_coastal_water};
    }
`

export const StyledFormHelperText = styled(FormHelperText)`
    &&{
        color: ${({ darkmode }) => darkmode === 'true' ? Colors.aqp_white : 'inherit'};
    }
`
