import {
    compose,
} from 'recompose';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return{
        inactiveSegments: state.routing.inactiveSegments,
    }
}

const Handlers = compose(
    connect(mapStateToProps)
);

export default Handlers;