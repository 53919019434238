import React, { Component } from 'react';
import { connect } from 'react-redux';
import MapButton from './MapButton';
import Layers from '@material-ui/icons/Layers';

import { toggleRequireWeatherMap } from '../../../store/redux/actions';

class MapLayerSelectButton extends Component {
  render() {
    const { toggleWeatherMap, requireWeatherMap, color } = this.props;

    return (
      <MapButton
        icon={<Layers />}
        callback={toggleWeatherMap}
        tooltip={requireWeatherMap ? "Show default map" : "Show weather map"}
        color={color}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    requireWeatherMap: state.map.requireWeatherMap
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleWeatherMap: () => dispatch(toggleRequireWeatherMap.call()),
  };
};

const MapLayerSelectButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MapLayerSelectButton);

export default MapLayerSelectButtonContainer;
