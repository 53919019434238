import * as actions from '../actions';
import { update } from './helper';

const getTotalPowerConsumption = completeRouteObject => {
  const power = completeRouteObject.vessel.power
  const hours = completeRouteObject.totalVoyageDurationInHours;
  return Math.round(power * hours);
}

const initialState = {
  routeApiPayload: null,
  routeApiResponse: null,
  isCalculating: false,
  secondRouteApiResponse: null,
  secondIsCalculating: false,
  routeObjectCache: {},
  actual: null,
  estimated: null
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    // case actions.setRouteCallResult.type:
    //   return update(state, {...state, ...payload})

    case actions.setActual.type:
      return update(state, { actual: payload })

    case actions.setEstimation.type:
      return update(state, { estimated: payload })

    case actions.NinetyPercentRouteCall.SUCCEEDED:
      console.debug(type)
        return update(state, {
            routeApiResponse: payload.vessel ? payload : {},
            isCalculating: false
          });

    case actions.SecondNinetyPercentRouteCall.SUCCEEDED:
      console.debug(type)
        return update(state, {
            secondRouteApiResponse: payload.vessel ? payload : {},
            secondIsCalculating: false
          });
    
    case actions.NinetyPercentRouteCall.FAILED:
    case actions.SecondNinetyPercentRouteCall.FAILED:
      console.debug(type)
            return update(state, { 
              ...initialState, 
              routeObjectCache: state.routeObjectCache,
              actual: state.actual,
              estimated: state.estimated
            });

    case actions.NinetyPercentRouteCall.REQUESTED:
      console.debug(type)
        return update(state, {
            routeApiPayload: payload.payload,
            routeApiResponse: null,
            isCalculating: true
            });

    case actions.SecondNinetyPercentRouteCall.REQUESTED:
      console.debug(type)
      return update(state, {
          secondRouteApiResponse: null,
          secondIsCalculating: true
          });

    case actions.addToInactiveSegments.type:
      //payload === uuid
      return update(state, {
        routeObjectCache: Object.assign({}, state.routeObjectCache, {
          [payload]: Object.assign({}, state.routeApiResponse, { totalPowerConsumption: getTotalPowerConsumption(state.routeApiResponse)})
        })
      });

    case actions.clearWaypoints.type:
      return update(state, initialState)

    default:
      return state;
  }
}

export default reducer;
