import React from 'react';
import LeafletMarker from './../LeafletMarker';
import { useDispatch } from "react-redux";
import { activateCurrentlyInactiveSegments } from './../../../../store/redux/actions';

const RouteMidpointPure = ({
    latlng,
    component,
    uuid
}) => {
    const dispatch = useDispatch();
    
    const setAsActive = () => {
        console.debug(`setting inactive route with uuid ${uuid} as active`);
        dispatch(activateCurrentlyInactiveSegments.call(uuid));
    }

    return (
        <LeafletMarker
            draggable={false}
            latlng={latlng}
            markerComponent={component}
            onClick={setAsActive}
        />
    )
}

export default RouteMidpointPure;