import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from './../../illustrations/14906-loading-amimation.json';
import { Wrapper } from './styledComponents';

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};

const Pure = () => {
    return (
        <Wrapper>
            <Lottie 
                options={defaultOptions}
                height={80}
                width={300}
            />
        </Wrapper>
    )
}

export default Pure;