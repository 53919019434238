import axios from 'axios';
import { functionWithApiEndpoints } from './../store/withApiEndpoints';

const axiosConfig = aqpToken => ({
  headers: {
    'Content-Type': 'application/json',
    'Aqp-Token': aqpToken
  },
});

export const ninetyPercentRouteApiCall = functionWithApiEndpoints(({ routeObject, aqpToken, apiEndpoint }) => {
  const endpoint = `${apiEndpoint}/90poe/route`;
//   const endpoint = "https://route-optimisation-demo-app.drydock.studio/api/v1/route";
  /*if(routeObject.weather){
    return new Promise((resolve) => resolve(Object.assign({}, routeObject, {
      vessel: Object.assign({}, routeObject.vessel, { power: Math.random() * 1e8})
    })))
  }
  else{
    //weather enrichment mockup
    const segments = routeObject.segments;
    const waypoints = [Object.assign({}, routeObject.source)]
                    .concat(segments.map(({ to }) => Object.assign({}, to)));
    const data = waypoints.reduce((acc, v) => {
      const wKey = `${v.lat}${Number.isInteger(v.lat) ? '.0' : ''}_${v.lng}${Number.isInteger(v.lng) ? '.0' : ''}`;
      return Object.assign({}, acc, {
        [wKey]: [{
          seaCurrentSpeedInKnots: Math.random()*10.0
        }]
      })
    }, {});
    return new Promise((resolve) => resolve(Object.assign({}, routeObject, {
      weather: {
        timestamp: "now",
        data: data
      }
    })))
  }*/
  if(endpoint){
    return axios
        .post(endpoint, routeObject, axiosConfig(aqpToken))
        .then(res => res.data )
        // .then(({ result }) => result)
        .catch(err => {
            // throw err;
            const segments = routeObject.segments;
            const waypoints = [routeObject.source].concat(segments.map(({ to }) => to));
            const data = waypoints.reduce((acc, v) => {
              const wKey = `${v.lat}${Number.isInteger(v.lat) ? '.0' : ''}_${v.lng}${Number.isInteger(v.lng) ? '.0' : ''}`;
              return Object.assign({}, acc, {
                [wKey]: [{
                  seaCurrentSpeedInKnots: 0
                }]
              })
            }, {});
            return new Promise((resolve) => resolve(Object.assign({}, routeObject, {
              weather: {
                timestamp: "now",
                data: data
              }
            })))
        });
  }
  else return new Promise((resolve) => resolve({}));
});

export const ninetyPercentRouteSegmentationApiCall = functionWithApiEndpoints(({ routeObject, aqpToken, apiEndpoint }) => {
  const endpoint = `${apiEndpoint}/90poe/route-segmentation`;
  if(endpoint){
    return axios
        .post(endpoint, routeObject, axiosConfig(aqpToken))
        .then(res => res.data )
        // .then(({ result }) => result)
        .catch(err => {
            throw err;
        });
  }
  else return new Promise((resolve) => resolve({}));
});